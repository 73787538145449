<template>
  <BasePageSection :bg-image="heroBgImg" class="home-hero px-0" fluid>
    <!-- TODO (ESS) : add vue qm adjustment for justify -->
    <VLayout row justify-end>
      <VFlex class="text-xs-left" lg5 md7 sm10 offset-sm1 xs12 right>
        <VCard
          :class="[
            'hero-cta elevation-0 py-4',
            `px-${$mq !== 'xs' ? '5' : '4'}`,
          ]"
        >
          <h2 class="hero-title blanch--font db-purple--text"
            >The <br v-if="['xs', 'sm'].includes($mq)" />Daily Beet</h2
          >

          <BasePageContent
            content-key="hero_text"
            default-content="A healthy cafe serving salads, grain bowls, cold-pressed juices, and more."
            tag-class="hero-text white--text"
            tag="div"
          />
          <BaseButtonOutline
            v-scroll-to="'#home-locations'"
            class="ml-0"
            color="deep-orange accent-2"
            :large="$mq !== 'xs'"
          >
            View {{ $mq !== 'xs' ? 'Our ' : '' }}Menus
          </BaseButtonOutline>
        </VCard>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
import getSiteAsset from '@utils/get-site-asset'

export default {
  name: 'SectionHomeHero',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('pages', ['getPageContent']),
    ...mapGetters('site', ['getSiteId']),
    heroBgImg() {
      return (
        this.getPageContent('hero_bg_img') ||
        getSiteAsset(`site-${this.getSiteId}-home-hero-bg.jpg`)
      )
    },
  },
}
</script>

<style lang="scss">
@import '@design';
@import '@styleMixins';

.v-card.hero-cta {
  background-color: $color-db-gold;
}
.hero-title {
  // TODO (ESS) : dont hard code
  @include font-size(91px, 0.55, 1, 1, 1);

  padding-bottom: 0.15em;
  margin: 0 0 0.3em;
  line-height: 0.8 !important;
  letter-spacing: 0.024em;
  border-bottom: 2px solid $color-db-purple;
}

.hero-text {
  // TODO (ESS) : dont hard code
  margin-bottom: 1em;
  font-size: 2rem;
  line-height: 1.4;

  @media only screen and (max-width: 414px) {
    font-size: 1.3rem;
  }
}
</style>
